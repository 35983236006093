:root {
  --usa-accordion-background-color: var(--usa-body-background);
  --usa-accordion-content-color: var(--usa-ink);
  --usa-accordion-border-color: var(--usa-base-lighter);
  --usa-accordion-border-width: var(--usa-spacing-05);
  --usa-accordion-button-background: var(--usa-base-lighter);
  --usa-accordion-button-color: var(--usa-link-color);
  --usa-accordion-button-active-background: var(--usa-base-lightest);
  --usa-accordion-button-active-color: var(--usa-link-active-color);
  --usa-accordion-button-hover-background: var(--usa-base-lighter);
  --usa-accordion-button-hover-color: var(--usa-link-hover-color);
  --usa-accordion-button-icon-size: var(--usa-spacing-3);
  --usa-accordion-button-padding-y: var(--usa-spacing-2);
  --usa-accordion-button-padding-x: var(--usa-spacing-205);
  --usa-accordion-button-font-family: var(--usa-font-body);
  --usa-accordion-button-font-weight: var(--usa-font-weight-semibold);
  --usa-accordion-button-font-size: var(--usa-font-size-body);
  --usa-accordion-button-line-height: var(--usa-line-height-body);
  --usa-accordion-padding-y: var(--usa-spacing-2);
  --usa-accordion-padding-x: var(--usa-spacing-205);
  --usa-accordion-border-radius: var(--usa-border-radius-0);
  --usa-primary-lightest: #cfe1ef;
  --usa-primary-lighter: #9fc3df;
  --usa-primary-light: #6fa4cf;
  --usa-primary-base: #1f577c;
  --usa-primary-vivid: #1f577c;
  --usa-primary-dark: #1a4c6a;
  --usa-primary-darker: #153e57;
  --usa-primary-darkest: #102e41;
  --usa-accent-cool-lightest: #f0cfe3;
  --usa-accent-cool-lighter: #e19fc7;
  --usa-accent-cool-light: #d26fab;
  --usa-accent-cool-base: #c33f8f;
  --usa-accent-cool-dark: #590046;
  --usa-accent-cool-darker: #480039;
  --usa-accent-cool-darkest: #37002c;
  --usa-accent-warm-lightest: var(--usa-color-orange-5);
  --usa-accent-warm-lighter: var(--usa-color-orange-10);
  --usa-accent-warm-light: var(--usa-color-orange-vivid-20);
  --usa-accent-warm-base: var(--usa-color-orange-vivid-30);
  --usa-accent-warm-dark: var(--usa-color-orange-vivid-50);
  --usa-accent-warm-darker: var(--usa-color-orange-60);
  --usa-accent-warm-darkest: var(--usa-color-orange-80);
  --usa-button-text-transform: capitalize;
  --usa-button-color: var(--usa-white);
  --usa-button-color-hover: var(--usa-white);
  --usa-button-color-active: var(--usa-white);
  --usa-button-background: var(--usa-primary);
  --usa-button-background-hover: var(--usa-primary-dark);
  --usa-button-background-active: var(--usa-primary-darker);
  --usa-button-inverse-color: var(--usa-link-reverse-color);
  --usa-button-inverse-hover-color: var(--usa-link-reverse-hover-color);
  --usa-button-inverse-active-color: var(--usa-link-reverse-active-color);
  --usa-button-font-family: var(--usa-font-ui);
  --usa-button-font-size: var(--usa-font-size-sm);
  --usa-button-line-height: var(--usa-line-height-3);
  --usa-button-lg-font-size: var(--usa-font-size-md);
  --usa-button-font-weight: var(--usa-font-weight-bold);
  --usa-button-border-radius: var(--usa-border-radius-md);
  --usa-button-lg-border-radius: var(--usa-border-radius-md);
  --usa-button-small-width: var(--usa-spacing-6);
  --usa-button-stroke-width: var(--usa-border-width-2px);
  --usa-button-outline-offset: var(--usa-focus-offset);
  --usa-button-padding-x: var(--usa-spacing-205);
  --usa-button-padding-y: var(--usa-spacing-105);
  --usa-button-lg-padding-x: var(--usa-spacing-205);
  --usa-button-lg-padding-y: var(--usa-spacing-105);
  --usa-button-margin-right: var(--usa-spacing-0);
  --usa-button-icon-size: var(--usa-spacing-3);
  --usa-button-lg-icon-size: var(--usa-spacing-4);
  --usa-button-outline-background: var(--usa-transparent);
  --usa-button-outline-background-hover: var(--usa-transparent);
  --usa-button-outline-background-active: var(--usa-transparent);
  --usa-button-outline-color: var(--usa-primary);
  --usa-button-outline-hover-color: var(--usa-primary-dark);
  --usa-button-outline-active-color: var(--usa-primary-darker);
  --usa-button-outline-border-color: var(--usa-primary);
  --usa-button-outline-border-hover-color: var(--usa-primary-dark);
  --usa-button-outline-border-active-color: var(--usa-primary-darker);
  --usa-font-size-3xs: clamp(
    calc(12px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(12px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(12px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-font-size-2xs: clamp(
    calc(14px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(14px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(14px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-font-size-xs: clamp(
    calc(15px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(15px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(15px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-font-size-sm: clamp(
    calc(16px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(16px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(16px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-font-size-md: clamp(
    calc(18px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(18px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(18px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-font-size-lg: clamp(
    calc(22px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(22px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(22px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-font-size-xl: clamp(
    calc(32px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(32px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(32px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-font-size-2xl: clamp(
    calc(40px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(40px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(40px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-font-size-3xl: clamp(
    calc(48px * 0.925 * var(--a11y-font-size-modifier)),
    calc(calc(48px * 0.925 * var(--a11y-font-size-modifier)) + 0.23vw),
    calc(48px * 1.075 * var(--a11y-font-size-modifier))
  );
  --usa-line-height-1: calc(100% * var(--a11y-line-height-modifier));
  --usa-line-height-2: calc(110% * var(--a11y-line-height-modifier));
  --usa-line-height-3: calc(125% * var(--a11y-line-height-modifier));
  --usa-line-height-4: calc(140% * var(--a11y-line-height-modifier));
  --usa-line-height-5: calc(150% * var(--a11y-line-height-modifier));
  --usa-line-height-6: calc(175% * var(--a11y-line-height-modifier));
  --usa-font-sans: 'Public Sans Web', Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  --usa-font-serif: Merriweather Web, Georgia, Cambria, Times New Roman, Times, serif;
  --usa-font-mono: 'Roboto Mono Web', 'Bitstream Vera Sans Mono', 'Consolas', 'Courier', monospace;
  --usa-font-heading: var(--usa-font-sans);
  --usa-font-ui: var(--usa-font-sans);
  --usa-font-code: var(--usa-font-mono);
  --usa-font-alt: var(--usa-font-serif);
  --usa-font-prose: var(--usa-font-sans);
  --usa-font-weight-heavy: 900;
  --usa-font-weight-bold: 700;
  --usa-font-weight-semibold: 600;
  --usa-font-weight-medium: 500;
  --usa-font-weight-normal: 400;
  --usa-font-weight-light: 300;
  --usa-font-weight-thin: 100;
  --usa-body-font-size: var(--usa-font-size-sm);
  --usa-body-font-family: var(--usa-font-sans);
  --usa-body-font-weight: var(--usa-font-weight-normal);
  --usa-body-font-line-height: var(--usa-line-height-5);
  --usa-body-font-letter-spacing: var(--usa-letter-spacing-1);
  --usa-body-font-paragraph-spacing: var(--usa-spacing-2);
  --usa-body-font-list-spacing: var(--usa-spacing-1);
  --usa-h1-font-size: var(--usa-font-size-2xl);
  --usa-h1-font-family: var(--usa-font-heading);
  --usa-h1-font-weight: var(--usa-font-weight-bold);
  --usa-h1-font-line-height: var(--usa-line-height-3);
  --usa-h1-font-letter-spacing: var(--usa-letter-spacing-1);
  --usa-h1-font-paragraph-spacing: var(--usa-spacing-3);
  --usa-h2-font-size: var(--usa-font-size-xl);
  --usa-h2-font-family: var(--usa-font-heading);
  --usa-h2-font-weight: var(--usa-font-weight-bold);
  --usa-h2-font-line-height: var(--usa-line-height-3);
  --usa-h2-font-letter-spacing: var(--usa-letter-spacing-1);
  --usa-h2-font-paragraph-spacing: var(--usa-spacing-1);
  --usa-h3-font-size: var(--usa-font-size-lg);
  --usa-h3-font-family: var(--usa-font-heading);
  --usa-h3-font-weight: var(--usa-font-weight-bold);
  --usa-h3-font-line-height: var(--usa-line-height-3);
  --usa-h3-font-letter-spacing: var(--usa-letter-spacing-1);
  --usa-h3-font-paragraph-spacing: var(--usa-spacing-1);
  --usa-h4-font-size: var(--usa-font-size-md);
  --usa-h4-font-family: var(--usa-font-heading);
  --usa-h4-font-weight: var(--usa-font-weight-medium);
  --usa-h4-font-line-height: var(--usa-line-height-2);
  --usa-h4-font-letter-spacing: var(--usa-letter-spacing-1);
  --usa-h4-font-paragraph-spacing: var(--usa-spacing-1);
  --usa-h5-font-size: var(--usa-font-size-xs);
  --usa-h5-font-family: var(--usa-font-heading);
  --usa-h5-font-weight: var(--usa-font-weight-bold);
  --usa-h5-font-line-height: var(--usa-line-height-3);
  --usa-h5-font-letter-spacing: var(--usa-letter-spacing-1);
  --usa-h5-font-paragraph-spacing: var(--usa-spacing-3);
  --usa-h6-font-size: var(--usa-font-size-2xs);
  --usa-h6-font-family: var(--usa-font-sans);
  --usa-h6-font-weight: var(--usa-font-weight-normal);
  --usa-h6-font-line-height: var(--usa-line-height-3);
  --usa-h6-font-letter-spacing: var(--usa-letter-spacing-2);
  --usa-h6-font-text-transform: uppercase;
  --usa-h6-font-paragraph-spacing: var(--usa-spacing-3);
  --usa-letter-spacing-0: 0em;
  --usa-letter-spacing-1: calc(0.025em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-2: calc(0.1em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-3: calc(0.15em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-neg-3: calc(-0.03em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-neg-2: calc(-0.02em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-neg-1: calc(-0.01em * var(--a11y-letter-spacing-modifier));
  --usa-letter-spacing-auto: initial;
  --usa-lead-font-size: var(--usa-font-size-lg);
  --usa-lead-font-family: var(--usa-body-font-family);
  --usa-lead-font-weight: var(--usa-font-weight-medium);
  --usa-lead-font-line-height: var(--usa-line-height-3);
  --usa-lead-font-letter-spacing: var(--usa-letter-spacing-0);
  --usa-lead-font-max-width: var(--usa-measure-base);
  --usa-lead-font-paragraph-spacing: var(--usa-spacing-3);
  --usa-body-background: var(--usa-color-gray-2);
  --usa-body-ink: var(--usa-base-darkest);
  --usa-table-border-width: var(--usa-border-width-md);
  --usa-table-cell-compact-padding-x: var(--usa-spacing-1);
  --usa-table-cell-compact-padding-y: var(--usa-spacing-05);
  --usa-table-cell-padding-x: var(--usa-spacing-2);
  --usa-table-cell-padding-y: var(--usa-spacing-1);
  --usa-table-unsorted-icon-hover-color: var(--usa-base-dark);
  --usa-table-unsorted-icon-color: var(--usa-base);
  --usa-table-sorted-stripe-background-color: var(--usa-accent-cool-light);
  --usa-table-sorted-background-color: var(--usa-accent-cool-lighter);
  --usa-table-sorted-header-background-color: var(--usa-accent-cool-light);
  --usa-table-text-hover-color: var(--usa-base-dark);
  --usa-table-stripe-background-color: var(--usa-base-lighter);
  --usa-table-header-background-color: var(--usa-base-lighter);
  --usa-table-border-color: var(--usa-body-ink);
  --usa-table-sorted-icon-color: var(--usa-table-text-color);
  --usa-table-sorted-header-text-color: var(--usa-table-text-color);
  --usa-table-text-color: var(--usa-body-ink);
  --usa-table-stripe-text-color: var(--usa-table-text-color);
  --usa-table-header-text-color: var(--usa-table-text-color);
  --usa-table-sorted-text-color: var(--usa-table-text-color);
  --usa-table-font-size: var(--usa-font-size-md);
  --usa-table-font-family: var(--usa-font-body);
  --usa-table-line-height: var(--usa-line-height-3);
  --usa-table-font-weight: var(--usa-font-weight-normal);
  --usa-table-header-font-size: var(--usa-font-size-md);
  --usa-table-header-font-family: var(--usa-font-body);
  --usa-table-header-font-weight: var(--usa-font-weight-medium);
  --usa-table-header-line-height: var(--usa-line-height-3);
  --usa-table-margin-y: var(--usa-spacing-205);
  --usa-table-caption-margin-bottom: var(--usa-spacing-105);
  --usa-table-caption-font-size: var(--usa-font-size-sm);
  --usa-table-caption-font-family: var(--usa-font-body);
  --usa-table-caption-font-weight: var(--usa-font-weight-semibold);
  --usa-table-caption-text-align: left;
  --usa-table-caption-line-height: var(--usa-line-height-5);
  --usa-table-background-color: var(--usa-body-background);
  --usa-sidenav-item-font-size: var(--usa-font-size-sm);
  --usa-sidenav-item-font-family: var(--usa-font-sans);
  --usa-sidenav-item-font-weight: var(--usa-font-weight-normal);
  --usa-sidenav-item-font-weight-current: var(--usa-font-weight-semibold);
  --usa-sidenav-item-line-height: var(--usa-line-height-4);
  --usa-sidenav-item-text-align: left;
  --usa-sidenav-sublist-font-size: var(--usa-sidenav-item-font-size);
  --usa-sidenav-sublist-font-weight: var(--usa-sidenav-item-font-weight);
  --usa-sidenav-sublist-font-weight-current: var(--usa-sidenav-item-font-weight-current);
  --usa-sidenav-sublist-line-height: var(--usa-sidenav-item-line-height);
  --usa-sidenav-sublist-font-family: var(--usa-sidenav-item-font-family);
  --usa-sidenav-item-background: var(--usa-white);
  --usa-sidenav-item-background-hover: var(--usa-primary-lightest);
  --usa-sidenav-item-background-current: var(--usa-sidenav-item-background);
  --usa-sidenav-sublist-background: var(--usa-sidenav-item-background);
  --usa-sidenav-sublist-background-hover: var(--usa-sidenav-item-background-hover);
  --usa-sidenav-sublist-background-current: var(--usa-sidenav-item-background-current);
  --usa-sidenav-border-color: var(--usa-base-lighter);
  --usa-sidenav-border-width: var(--usa-border-width-md);
  --usa-sidenav-border-radius: var(--usa-border-radius-lg);
  --usa-sidenav-item-border-color: var(--usa-base-lighter);
  --usa-sidenav-item-border-width: var(--usa-border-width-1px);
  --usa-sidenav-sublist-border-color: var(--usa-sidenav-item-border-color);
  --usa-sidenav-sublist-border-width: var(--usa-sidenav-item-border-width);
  --usa-sidenav-item-padding-x: var(--usa-spacing-2);
  --usa-sidenav-item-padding-y: var(--usa-spacing-1);
  --usa-sidenav-item-margin-bottom: var(--usa-spacing-0);
  --usa-sidenav-item-margin-top: var(--usa-spacing-0);
  --usa-sidenav-item-color: var(--usa-ink);
  --usa-sidenav-item-color-hover: var(--usa-primary-dark);
  --usa-sidenav-item-color-current: var(--usa-primary);
  --usa-sidenav-item-text-decoration: none;
  --usa-sidenav-sublist-item-color: var(--usa-sidenav-item-color);
  --usa-sidenav-sublist-item-color-hover: var(--usa-sidenav-item-color-hover);
  --usa-sidenav-sublist-item-color-current: var(--usa-sidenav-item-color-current);
  --usa-sidenav-sublist-item-text-decoration: var(--usa-sidenav-item-text-decoration);
  --usa-sidenav-item-focus-outline-offset: 0;
  --usa-sidenav-item-current-indicator-color: var(--usa-primary);
  --usa-sidenav-item-current-indicator-border-radius: var(--usa-border-radius-pill);
  --usa-sidenav-item-current-indicator-width: var(--usa-spacing-05);
  --usa-sidenav-item-current-indicator-position: 2%;
  --usa-sidenav-child-padding-left: var(--usa-spacing-2);
  --usa-sidenav-child-padding-right: var(--usa-spacing-0);
  --usa-sidenav-child-padding-multiplier: 2;
  --usa-input-icon-size: var(--usa-spacing-3);
  --usa-input-font-family: var(--usa-font-ui);
  --usa-input-font-size: var(--usa-body-font-size);
  --usa-input-font-weight: var(--usa-font-weight-normal);
  --usa-input-line-height: var(--usa-line-height-4);
  --usa-input-letter-spacing: var(--usa-body-font-letter-spacing);
  --usa-input-height: var(--usa-spacing-5);
  --usa-input-select-icon: url(/assets/usa-icons/unfold_more.svg);
  --usa-input-select-icon-size: var(--usa-spacing-205);
  --usa-input-select-icon-color: var(--usa-base-light);
  --usa-input-max-width: var(--usa-spacing-mobile-lg);
  --usa-input-margin-top: var(--usa-spacing-1);
  --usa-input-padding-x: var(--usa-spacing-1);
  --usa-input-padding-y: var(--usa-spacing-05);
  --usa-input-large-height: var(--usa-spacing-6);
  --usa-input-border-width: var(--usa-border-width-sm);
  --usa-input-border-color: var(--usa-ink);
  --usa-input-border-color-hover: var(--usa-ink);
  --usa-input-color: var(--usa-ink);
  --usa-input-placeholder-color: var(--usa-base);
  --usa-input-background-color: var(--usa-white);
  --usa-input-border-radius: var(--usa-border-radius-0);
  --usa-input-search-min-width: 27ch;
  --usa-input-disabled-border-color: var(--usa-disabled-light);
  --usa-input-disabled-border-color-hover: var(--usa-input-disabled-border-color);
  --usa-input-disabled-color: var(--usa-disabled);
  --usa-input-disabled-background-color: var(--usa-disabled-lighter);
  --usa-input-label-font-size: var(--usa-body-font-size);
  --usa-input-label-font-family: var(--usa-body-font-family);
  --usa-input-label-font-weight: var(--usa-body-font-weight);
  --usa-input-label-color: var(--usa-ink);
  --usa-input-label-line-height: var(--usa-body-font-line-height);
  --usa-input-label-letter-spacing: var(--usa-body-font-letter-spacing);
  --usa-input-label-margin-bottom: var(--usa-spacing-05);
  --usa-input-label-margin-top: var(--usa-spacing-2);
  --usa-base-lightest: var(--usa-color-gray-5);
  --usa-base-lighter: var(--usa-color-gray-cool-10);
  --usa-base-light: var(--usa-color-gray-cool-30);
  --usa-base-base: var(--usa-color-gray-cool-50);
  --usa-base-dark: var(--usa-color-gray-cool-60);
  --usa-base-darker: var(--usa-color-gray-cool-70);
  --usa-base-darkest: var(--usa-color-gray-90);
  --usa-info-lighter: var(--usa-color-cyan-5);
  --usa-info-light: var(--usa-color-cyan-20);
  --usa-info-base: var(--usa-color-cyan-vivid-30);
  --usa-info-dark: var(--usa-color-cyan-vivid-40);
  --usa-info-darker: var(--usa-color-blue-cool-60);
  --usa-success-lighter: var(--usa-color-green-5);
  --usa-success-light: var(--usa-color-green-10);
  --usa-success-base: var(--usa-color-green-vivid-40);
  --usa-success-dark: var(--usa-color-green-vivid-50);
  --usa-success-darker: var(--usa-color-green-vivid-70);
  --usa-warning-lighter: var(--usa-color-yellow-5);
  --usa-warning-light: var(--usa-color-yellow-vivid-10);
  --usa-warning-base: var(--usa-color-gold-vivid-20);
  --usa-warning-dark: var(--usa-color-gold-vivid-30);
  --usa-warning-darker: var(--usa-color-gold-vivid-50);
  --usa-error-lighter: var(--usa-color-red-warm-10);
  --usa-error-light: var(--usa-color-red-warm-vivid-30);
  --usa-error-base: var(--usa-color-red-warm-vivid-50);
  --usa-error-dark: var(--usa-color-red-vivid-60);
  --usa-error-darker: var(--usa-color-red-70);
  --usa-disabled-lighter: var(--usa-color-gray-10);
  --usa-disabled-light: var(--usa-color-gray-20);
  --usa-disabled-base: var(--usa-color-gray-40);
  --usa-disabled-dark: var(--usa-color-gray-50);
  --usa-disabled-darker: var(--usa-color-gray-60);
  --usa-border-radius-0: var(--usa-spacing-0);
  --usa-border-radius-sm: var(--usa-spacing-2px);
  --usa-border-radius-md: var(--usa-spacing-05);
  --usa-border-radius-lg: var(--usa-spacing-1);
  --usa-border-radius-pill: var(--usa-spacing-tablet);
  --usa-border-color-lighter: var(--usa-base-lighter);
  --usa-border-color-light: var(--usa-base-light);
  --usa-border-color-base: var(--usa-base);
  --usa-border-color-dark: var(--usa-base-dark);
  --usa-border-color-darker: var(--usa-base-darker);
  --usa-border-width-0: var(--usa-spacing-0);
  --usa-border-width-sm: var(--usa-spacing-1px);
  --usa-border-width-md: var(--usa-spacing-2px);
  --usa-border-width-lg: var(--usa-spacing-05);
  --usa-radio-size: var(--usa-spacing-205);
  --usa-radio-gap: var(--usa-spacing-2);
  --usa-radio-background-color: var(--usa-body-background);
  --usa-radio-label-color: var(--usa-ink);
  --usa-radio-label-font-family: var(--usa-input-font-family);
  --usa-radio-label-font-size: var(--usa-input-font-size);
  --usa-radio-label-font-weight: var(--usa-input-font-weight);
  --usa-radio-label-line-height: var(--usa-input-line-height);
  --usa-radio-label-letter-spacing: var(--usa-input-letter-spacing);
  --usa-radio-border-color: var(--usa-base-base);
  --usa-radio-border-width: var(--usa-border-width-md);
  --usa-radio-border-radius: var(--usa-border-radius-pill);
  --usa-radio-checked-background-color: var(--usa-primary);
  --usa-radio-checked-border-color: var(--usa-primary);
  --usa-radio-focus-border-color: var(--usa-input-focus-border-color);
  --usa-radio-focus-border-width: var(--usa-input-focus-border-width);
  --usa-radio-disabled-background-color: var(--usa-body-background);
  --usa-radio-disabled-border-color: var(--usa-disabled-base);
  --usa-radio-disabled-color: var(--usa-disabled-base);
  --usa-radio-tile-padding-y: var(--usa-spacing-2);
  --usa-radio-tile-padding-x: var(--usa-spacing-2);
  --usa-radio-tile-background-color: var(--usa-input-background-color);
  --usa-radio-tile-label-color: var(--usa-ink);
  --usa-radio-tile-border-radius: var(--usa-border-radius-md);
  --usa-radio-tile-border-color: var(--usa-base-base);
  --usa-radio-tile-border-width: var(--usa-radio-border-width);
  --usa-radio-tile-checked-background-color: var(--usa-primary-lightest);
  --usa-radio-tile-checked-border-color: var(--usa-radio-checked-border-color);
  --usa-secondary-lightest: var(--usa-color-red-5);
  --usa-secondary-lighter: var(--usa-color-red-cool-vivid-10);
  --usa-secondary-light: var(--usa-color-red-30);
  --usa-secondary-base: var(--usa-color-red-50);
  --usa-secondary-vivid: var(--usa-color-red-cool-vivid-50);
  --usa-secondary-dark: var(--usa-color-red-vivid-60);
  --usa-secondary-darker: var(--usa-color-red-vivid-70);
  --usa-secondary-darkest: var(--usa-color-red-80);
  --usa-color-black: #0a0a0a;
  --usa-color-white: #fefefe;
  --usa-color-red: #e52207;
  --usa-color-red-warm: var(--usa-color-red-warm-40);
  --usa-color-orange: #e66f0e;
  --usa-color-orange-warm: var(--usa-color-orange-warm-40);
  --usa-color-gold: #ffbe2e;
  --usa-color-yellow: #fee685;
  --usa-color-green-warm: var(--usa-color-green-warm-40);
  --usa-color-green: #538200;
  --usa-color-green-cool: var(--usa-color-green-cool-40);
  --usa-color-mint: #04c585;
  --usa-color-mint-cool: var(--usa-color-mint-cool-40);
  --usa-color-cyan: #009ec1;
  --usa-color-blue: #0076d6;
  --usa-color-blue-warm: var(--usa-color-blue-warm-40);
  --usa-color-indigo-cool: var(--usa-color-indigo-cool-40);
  --usa-color-indigo: #676cc8;
  --usa-color-indigo-warm: var(--usa-color-indigo-warm-40);
  --usa-color-violet: #8168b3;
  --usa-color-violet-warm: var(--usa-color-violet-warm-40);
  --usa-color-magenta: #d72d79;
  --usa-color-gray-cool: var(--usa-color-gray-cool-40);
  --usa-color-gray: gray;
  --usa-spacing-0: 0;
  --usa-spacing-1: calc(8px * var(--a11y-spacing-modifier));
  --usa-spacing-2: calc(16px * var(--a11y-spacing-modifier));
  --usa-spacing-3: calc(24px * var(--a11y-spacing-modifier));
  --usa-spacing-4: calc(32px * var(--a11y-spacing-modifier));
  --usa-spacing-5: calc(40px * var(--a11y-spacing-modifier));
  --usa-spacing-6: calc(48px * var(--a11y-spacing-modifier));
  --usa-spacing-7: calc(56px * var(--a11y-spacing-modifier));
  --usa-spacing-8: calc(64px * var(--a11y-spacing-modifier));
  --usa-spacing-9: calc(72px * var(--a11y-spacing-modifier));
  --usa-spacing-10: calc(80px * var(--a11y-spacing-modifier));
  --usa-spacing-15: calc(120px * var(--a11y-spacing-modifier));
  --usa-spacing-105: calc(12px * var(--a11y-spacing-modifier));
  --usa-spacing-205: calc(20px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-15: calc(-120px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-10: calc(-80px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-9: calc(-72px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-8: calc(-64px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-7: calc(-56px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-6: calc(-48px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-5: calc(-40px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-4: calc(-32px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-3: calc(-24px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-205: calc(-20px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-2: calc(-16px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-105: calc(-12px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-1: calc(-8px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-05: calc(-4px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-2px: calc(-2px * var(--a11y-spacing-modifier));
  --usa-spacing-neg-1px: calc(-1px * var(--a11y-spacing-modifier));
  --usa-spacing-1px: calc(1px * var(--a11y-spacing-modifier));
  --usa-spacing-2px: calc(2px * var(--a11y-spacing-modifier));
  --usa-spacing-05: calc(4px * var(--a11y-spacing-modifier));
  --usa-spacing-card: calc(160px * var(--a11y-spacing-modifier));
  --usa-spacing-card-lg: calc(240px * var(--a11y-spacing-modifier));
  --usa-spacing-mobile: calc(320px * var(--a11y-spacing-modifier));
  --usa-spacing-mobile-lg: calc(480px * var(--a11y-spacing-modifier));
  --usa-spacing-tablet: calc(640px * var(--a11y-spacing-modifier));
  --usa-spacing-tablet-lg: calc(880px * var(--a11y-spacing-modifier));
  --usa-spacing-desktop: calc(1024px * var(--a11y-spacing-modifier));
  --usa-spacing-desktop-lg: calc(1200px * var(--a11y-spacing-modifier));
  --usa-spacing-widescreen: calc(1400px * var(--a11y-spacing-modifier));
  --usa-focus-offset: var(--usa-spacing-05);
  --usa-focus-width: var(--usa-spacing-05);
  --usa-focus-style: solid;
  --usa-focus-color: var(--usa-color-mint-vivid-30);
  --usa-step-indicator-background-color: transparent;
  --usa-step-indicator-counter-gap: var(--usa-spacing-05);
  --usa-step-indicator-counter-border-width: var(--usa-spacing-05);
  --usa-step-indicator-font-family: var(--usa-font-ui);
  --usa-step-indicator-heading-color: var(--usa-text-color);
  --usa-step-indicator-heading-font-family: var(--usa-font-ui);
  --usa-step-indicator-heading-font-size: var(--usa-font-size-xl);
  --usa-step-indicator-heading-font-size-small: var(--usa-font-size-md);
  --usa-step-indicator-label-min-height: var(--usa-spacing-6);
  --usa-step-indicator-label-font-size: var(--usa-font-size-sm);
  --usa-step-indicator-label-font-size-current: var(--usa-font-size-md);
  --usa-step-indicator-label-line-height: var(--usa-line-height-2);
  --usa-step-indicator-label-font-weight: var(--usa-font-weight-normal);
  --usa-step-indicator-label-font-weight-current: var(--usa-font-weight-semibold);
  --usa-step-indicator-label-background: var(--usa-step-indicator-background-color);
  --usa-step-indicator-label-background-current: #3e4ded;
  --usa-step-indicator-label-background-complete: var(--usa-base-dark);
  --usa-step-indicator-label-padding-y: var(--usa-spacing-05);
  --usa-step-indicator-label-padding-x: var(--usa-spacing-1);
  --usa-step-indicator-min-width: var(--usa-spacing-tablet);
  --usa-step-indicator-segment-color-pending: var(--usa-base-light);
  --usa-step-indicator-segment-color-complete: var(--usa-base-darkest);
  --usa-step-indicator-segment-color-current: #222fbf;
  --usa-step-indicator-segment-gap: var(--usa-spacing-0);
  --usa-step-indicator-segment-height: var(--usa-spacing-1);
  --usa-step-indicator-segment-max-width: var(--usa-spacing-card-lg);
  --usa-step-indicator-text-pending-color: var(--usa-base-dark);
  --usa-step-indicator-text-current-color: var(--usa-white);
  --usa-step-indicator-text-complete-color: var(--usa-white);
  --usa-step-indicator-label-margin-top: var(--usa-spacing-0);
  --usa-step-indicator-margin-bottom: var(--usa-spacing-0);
  --usa-step-indicator-header-margin-top: var(--usa-spacing-2);
  --usa-step-indicator-header-margin-top-sm: var(--usa-spacing-2);
  --usa-step-indicator-segment-height-mobile: var(--usa-spacing-1);
  --usa-step-indicator-counter-size: var(--usa-spacing-5);
  --usa-step-indicator-counter-size-sm: var(--usa-spacing-3);
  --usa-step-indicator-current-step-color: var(--usa-white);
  --usa-step-indicator-current-step-background: var(--usa-primary);
  --usa-step-indicator-total-steps-color: var(--usa-primary-dark);
  --usa-step-indicator-heading-font-feature-settings: normal;
  --usa-step-indicator-label-complete-icon-size: var(--usa-spacing-3);
  --usa-link-reverse-active-color: var(--usa-white);
  --usa-link-visited-color: var(--usa-color-violet-warm-vivid-60);
  --usa-link-reverse-hover-color: var(--usa-base-lightest);
  --usa-link-reverse-color: var(--usa-base-lighter);
  --usa-link-active-color: var(--usa-primary-darker);
  --usa-link-hover-color: var(--usa-primary-dark);
  --usa-link-color: var(--usa-primary-base);
  --usa-link-focus-offset: var(--usa-spacing-0);
  --usa-data-rep-focus-outline-width: var(--usa-focus-width);
  --usa-data-rep-focus-outline-color: var(--usa-focus-color);
  --usa-data-rep-focus-outline-offset: var(--usa-focus-offset);
  --usa-data-rep-modal-background: var(--usa-modal-overlay-background-color);
  --usa-data-rep-modal-z-index: 1000;
  --usa-data-rep-modal-content-background: var(--usa-modal-background-color);
  --usa-data-rep-modal-content-padding-y: var(--usa-data-rep-padding-y);
  --usa-data-rep-modal-content-padding-x: var(--usa-data-rep-padding-x);
  --usa-data-rep-modal-content-border-radius: var(--usa-modal-border-radius);
  --usa-data-rep-modal-content-border-width: var(--usa-modal-border-width);
  --usa-data-rep-modal-content-border-color: var(--usa-modal-border-color);
  --usa-data-rep-modal-content-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  --usa-data-rep-modal-content-min-width: 50%;
  --usa-data-rep-modal-content-max-width: 90%;
  --usa-data-rep-modal-content-max-height: 90%;
  --usa-data-rep-modal-content-overflow: scroll;
  --usa-data-rep-modal-content-gap: var(--usa-data-rep-container-gap);
  --usa-data-rep-modal-content-font-family: var(--usa-data-rep-container-content-font-family);
  --usa-data-rep-modal-content-font-size: 1rem;
  --usa-data-rep-modal-content-font-weight: var(--usa-data-rep-container-content-font-weight);
  --usa-data-rep-modal-content-line-height: var(--usa-data-rep-container-content-line-height);
  --usa-data-rep-modal-content-ink: var(--usa-data-rep-container-ink);
  --usa-data-rep-modal-heading-ink: var(--usa-data-rep-container-ink);
  --usa-data-rep-modal-heading-font-family: var(--usa-data-rep-container-heading-font-family);
  --usa-data-rep-modal-heading-font-size: var(--usa-data-rep-container-heading-font-size);
  --usa-data-rep-modal-heading-font-weight: var(--usa-data-rep-container-heading-font-weight);
  --usa-data-rep-modal-heading-line-height: var(--usa-data-rep-container-heading-line-height);
  --usa-data-rep-modal-close-button-background: none;
  --usa-data-rep-modal-close-button-color: #666;
  --usa-data-rep-modal-close-button-background-color: var(--usa-data-rep-action-bar-item-background);
  --usa-data-rep-modal-close-button-hover-background: var(--usa-data-rep-action-bar-item-hover-background);
  --usa-data-rep-modal-close-button-hover-color: var(--usa-data-rep-modal-content-ink);
  --usa-data-rep-modal-close-button-gap: var(--usa-data-rep-action-bar-item-gap);
  --usa-data-rep-modal-close-button-border-radius: var(--usa-data-rep-action-bar-item-border-radius);
  --usa-data-rep-modal-close-button-padding-y: 0.25rem;
  --usa-data-rep-modal-close-button-padding-x: 0.5rem;
  --usa-data-rep-modal-close-button-font-family: var(--usa-data-rep-action-bar-item-font-family);
  --usa-data-rep-modal-close-button-font-size: var(--usa-data-rep-action-bar-item-font-size);
  --usa-data-rep-modal-close-button-font-weight: var(--usa-data-rep-action-bar-item-font-weight);
  --usa-data-rep-modal-close-button-icon-stroke-width: var(--usa-data-rep-action-bar-button-icon-stroke-width);
  --usa-data-rep-modal-close-button-icon-size: var(--usa-data-rep-action-bar-button-icon-size);
  --usa-data-rep-table-background: var(--usa-table-background-color);
  --usa-data-rep-table-stripe-background: var(--usa-data-rep-table-background);
  --usa-data-rep-table-border-color: var(--usa-table-border-color);
  --usa-data-rep-table-border-width: var(--usa-table-border-width);
  --usa-data-rep-table-border-radius: 0;
  --usa-data-rep-table-row-gap: 0;
  --usa-data-rep-table-col-gap: 0;
  --usa-data-rep-table-ink: var(--usa-table-text-color);
  --usa-data-rep-table-stripe-ink: var(--usa-table-stripe-text-color);
  --usa-data-rep-table-font-family: var(--usa-table-font-family);
  --usa-data-rep-table-numeral-font-family: var(--usa-data-rep-numeral-font-family);
  --usa-data-rep-table-font-size: var(--usa-table-font-size);
  --usa-data-rep-table-font-weight: var(--usa-table-font-weight);
  --usa-data-rep-table-line-height: var(--usa-table-line-height);
  --usa-data-rep-table-header-background: var(--usa-data-rep-table-background);
  --usa-data-rep-table-header-ink: var(--usa-table-header-text-color);
  --usa-data-rep-table-header-font-size: var(--usa-table-header-font-size);
  --usa-data-rep-table-header-font-weight: var(--usa-table-header-font-weight);
  --usa-data-rep-table-header-line-height: var(--usa-table-header-line-height);
  --usa-data-rep-table-header-padding-y: var(--usa-table-cell-padding-y);
  --usa-data-rep-table-header-padding-x: var(--usa-table-cell-padding-x);
  --usa-data-rep-table-cell-padding-y: var(--usa-table-cell-padding-y);
  --usa-data-rep-table-cell-padding-x: var(--usa-table-cell-padding-x);
  --usa-data-rep-table-footer-background: var(--usa-data-rep-table-header-background);
  --usa-data-rep-table-footer-ink: var(--usa-data-rep-table-header-ink);
  --usa-data-rep-table-footer-font-size: var(--usa-data-rep-table-font-size);
  --usa-data-rep-table-footer-font-weight: var(--usa-data-rep-table-font-weight);
  --usa-data-rep-table-footer-line-height: var(--usa-data-rep-table-line-height);
  --usa-data-rep-table-footer-padding-y: var(--usa-data-rep-table-cell-padding-y);
  --usa-data-rep-table-footer-padding-x: var(--usa-data-rep-table-cell-padding-x);
  --usa-data-rep-container-max-width: 100%;
  --usa-data-rep-container-margin-y: 0;
  --usa-data-rep-container-margin-x: 0;
  --usa-data-rep-padding-y: var(--usa-spacing-3);
  --usa-data-rep-padding-x: var(--usa-spacing-3);
  --usa-data-rep-container-gap: var(--usa-spacing-1);
  --usa-data-rep-container-background: var(--usa-white);
  --usa-data-rep-container-ink: var(--usa-ink);
  --usa-data-rep-container-border-color: var(--usa-base-lighter);
  --usa-data-rep-container-border-width: var(--usa-spacing-2px);
  --usa-data-rep-container-border-radius: var(--usa-border-radius-lg);
  --usa-data-rep-container-content-font-family: var(--usa-font-body);
  --usa-data-rep-container-content-font-size: var(--usa-font-size-lg);
  --usa-data-rep-container-content-line-height: var(--usa-line-height-5);
  --usa-data-rep-container-content-font-weight: var(--usa-font-weight-normal);
  --usa-data-rep-container-heading-font-family: var(--usa-font-heading);
  --usa-data-rep-container-heading-font-size: var(--usa-font-size-lg);
  --usa-data-rep-container-heading-font-weight: var(--usa-font-weight-bold);
  --usa-data-rep-container-heading-line-height: var(--usa-line-height-3);
  --usa-data-rep-numeral-font-family: var(--usa-data-rep-container-content-font-family);
  --usa-data-rep-action-bar-padding-y: var(--usa-spacing-05);
  --usa-data-rep-action-bar-padding-x: var(--usa-data-rep-padding-x);
  --usa-data-rep-action-bar-gap: var(--usa-data-rep-container-gap);
  --usa-data-rep-action-bar-item-background: transparent;
  --usa-data-rep-action-bar-item-hover-background: var(--usa-base-lighter);
  --usa-data-rep-action-bar-item-gap: var(--usa-spacing-1);
  --usa-data-rep-action-bar-item-border-radius: var(--usa-button-border-radius);
  --usa-data-rep-action-bar-item-padding-y: var(--usa-spacing-1);
  --usa-data-rep-action-bar-item-padding-x: var(--usa-spacing-1);
  --usa-data-rep-action-bar-item-font-family: var(--usa-button-font-family);
  --usa-data-rep-action-bar-item-font-size: var(--usa-font-size-md);
  --usa-data-rep-action-bar-item-font-weight: var(--usa-font-weight-normal);
  --usa-data-rep-action-bar-button-color: var(--usa-primary-base);
  --usa-data-rep-action-bar-button-icon-stroke-width: 2;
  --usa-data-rep-action-bar-button-icon-size: var(--usa-button-icon-size);
  --usa-data-rep-action-bar-toggle-indicator-background: var(--usa-data-rep-container-background);
  --usa-data-rep-action-bar-toggle-indicator-size: 1.25rem;
  --usa-data-rep-action-bar-toggle-indicator-border-radius: 1.25rem;
  --usa-data-rep-action-bar-toggle-track-background: var(--usa-data-rep-container-ink);
  --usa-data-rep-action-bar-toggle-track-border-color: var(--usa-data-rep-action-bar-toggle-track-background);
  --usa-data-rep-action-bar-toggle-track-active-background: var(--usa-data-rep-action-bar-button-color);
  --usa-data-rep-action-bar-toggle-track-active-border-color: var(
    --usa-data-rep-action-bar-toggle-track-active-background
  );
  --usa-data-rep-action-bar-toggle-track-border-radius: 1.25rem;
  --usa-data-rep-action-bar-toggle-track-border-width: var(--usa-data-rep-container-border-width);
  --usa-data-rep-action-bar-item-outline-offset: var(--usa-button-outline-offset);
  --usa-data-rep-title-font-family: var(--usa-data-rep-container-heading-font-family);
  --usa-data-rep-title-font-size: var(--usa-data-rep-container-heading-font-size);
  --usa-data-rep-title-font-weight: var(--usa-data-rep-container-heading-font-weight);
  --usa-data-rep-title-line-height: var(--usa-data-rep-container-heading-line-height);
  --usa-data-rep-insight-font-family: var(--usa-data-rep-container-content-font-family);
  --usa-data-rep-insight-font-size: var(--usa-data-rep-container-content-font-size);
  --usa-data-rep-insight-font-weight: var(--usa-data-rep-container-content-font-weight);
  --usa-data-rep-insight-line-height: var(--usa-data-rep-container-content-line-height);
  --usa-data-rep-series-group-padding-y: var(--usa-spacing-2);
  --usa-data-rep-series-group-padding-x: var(--usa-spacing-205);
  --usa-data-rep-series-group-gap: var(--usa-spacing-205);
  --usa-data-rep-series-item-background: var(--usa-primary-base);
  --usa-data-rep-series-item-gap: var(--usa-spacing-1);
  --usa-data-rep-series-item-min-width: 0.125rem;
  --usa-data-rep-series-item-border-radius: 0;
  --usa-data-rep-series-item-flex-amount: 1;
  --usa-data-rep-series-item-font-family: var(--usa-data-rep-container-content-font-family);
  --usa-data-rep-series-label-font-size: var(--usa-font-size-sm);
  --usa-data-rep-series-label-font-weight: var(--usa-font-weight-bold);
  --usa-data-rep-series-label-line-height: var(--usa-data-rep-container-content-line-height);
  --usa-data-rep-series-definition-font-size: var(--usa-font-size-sm);
  --usa-data-rep-series-definition-font-weight: var(--usa-font-weight-normal);
  --usa-data-rep-series-definition-line-height: var(--usa-data-rep-container-content-line-height);
  --usa-data-rep-series-value-font-size: var(--usa-font-size-lg);
  --usa-data-rep-series-value-font-weight: var(--usa-font-weight-semibold);
  --usa-data-rep-series-value-line-height: var(--usa-data-rep-container-content-line-height);
  --usa-data-rep-series-percentage-font-size: var(--usa-font-size-sm);
  --usa-data-rep-series-percentage-font-weight: var(--usa-font-weight-normal);
  --usa-data-rep-series-percentage-line-height: var(--usa-data-rep-container-content-line-height);
  --usa-data-rep-series-percentage-background: var(--usa-base-lightest);
  --usa-data-rep-plain-language-font-family: var(--usa-data-rep-container-content-font-family);
  --usa-data-rep-plain-language-font-size: var(--usa-font-size-sm);
  --usa-data-rep-plain-language-font-weight: var(--usa-data-rep-container-content-font-weight);
  --usa-data-rep-plain-language-line-height: var(--usa-data-rep-container-content-line-height);
  --usa-data-rep-plain-language-background: var(--usa-primary-lightest);
  --usa-data-rep-plain-language-color: var(--usa-data-rep-container-ink);
  --usa-data-rep-plain-language-padding-y: 1rem;
  --usa-data-rep-plain-language-padding-x: var(--usa-data-rep-padding-x);
  --usa-data-rep-plain-language-title-font-size: var(--usa-data-rep-plain-language-font-size);
  --usa-data-rep-plain-language-title-font-weight: var(--usa-data-rep-container-heading-font-weight);
  --usa-data-rep-plain-language-title-line-height: var(--usa-data-rep-container-heading-line-height);
  --usa-data-rep-plain-language-title-font-family: var(--usa-data-rep-container-heading-font-family);
}
